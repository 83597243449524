
 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

 html,
 body,
 #root,
 .app,
 .content{
  height: 100%;
  width: 100%;
  font-family:'Source Sans 3', sans-serif ;
}
.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width:10px;
}

::-webkit-scrollbar-track{
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb{
  background: #888;
}

::-webkit-scrollbar-thumb:hover{
  background: #555;
}
@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica.ttf') format('truetype');
  /* Add other font formats if needed */
}
@font-face {
  font-family: 'FM-Gangani';
  src: url('./fonts/FM-Gangani.ttf') format('truetype');
  /* Add other font formats if needed */
}