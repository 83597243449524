.carousel {
    display: flex;
    height: 100%;
    width: 100%;
    
  }
  
  .carousel_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .carousel_card {
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    opacity: 0;
    pointer-events: none;
    transform: scale(0.8);
    transition: 0.5s ease-in-out;
  }
  
  .carousel_card-active {
    opacity: 1;
    transform: scale(1);
    pointer-events: visible;
  }
  
  .card_image {
    width: 100%;
    object-fit:fill;
  }
  
  .card_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.185);
    display: flex;
    padding: 40px 30px;
    align-items:center;
    justify-content: flex-end;
  }


